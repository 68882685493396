/* You can add global styles to this file, and also import other style files */
//== Variables
@import "./variables.scss";
@import "./constants.scss";
@import "./overrides.scss";

@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "./assets/material-theme/wsh-theme.scss";

body {
  font-family: "Source Sans Pro", sans-serif;
  height: 100%;
  scroll-behavior: smooth;
  margin: 0;
  background: black;
  font-size: 1rem;
}

html {
  scroll-behavior: smooth;
}

.outside-content {
  min-height: 100vh;
  background-image: linear-gradient(120deg, #ffffff, #ffffff, #ff0000, #ffffff, #ffffff);
  background-repeat: no-repeat;
  background-size: 400%;
  animation: background-moving 12s infinite;
  padding: 20px 0;
}

@keyframes background-moving {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

span:focus {
  outline: none !important;
  border: 0 !important;
}

.btn-container {
  position: absolute;
  right: 15px;
  top: 15px;
}

.btn-text {
  margin-left: 0.25rem !important;
}

// .btn-icon {
//     .mat-button-wrapper {
//         .mat-icon {
//             font-size: 16px;
//             margin-top: 8px;
//         }
//     }
// }

@media (max-width: $breakpoint-md) {
  .btn-text:not(.d-always) {
    display: none;
  }

  .btn-container {
    .dx-button-text {
      display: none;
    }
  }
}

.form-vertical {
  padding: 20px;
  background: $secondary-theme-color;
  box-shadow: 0px 0px 1px 0px $main-theme-color;
}

.table-icon {
  cursor: pointer;
  color: $main-theme-color;
  padding-right: 6px;
  font-size: 18px;
}

.form-panel {
  box-shadow: 0px 0px 1px 0px $main-theme-color;
}

.wsh-hr {
  background-color: $main-theme-color;
}

.hr-margin {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.details-title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: larger;
}

.text-xx-large {
  font-size: xx-large;
}

.dot {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline-block;
}

.custom-select {
  padding: 8px;
  width: 134px;
  border: none;
  margin-bottom: 10px;
  outline: none;

  option {
    padding: 8px;
  }
}

.table-label {
  border-radius: 15px;
  padding: 3px;
  font-size: 0.6rem;
  color: white;
  text-align: center;
  display: block;

  &.status-active {
    background: #54b81a;
  }

  &.status-deactivated {
    background: #ea192a;
  }

  &.status-draft {
    background: #ffbc00;
  }
}

//   .select-selected {
//     background-color: DodgerBlue;
//   }

//   /*style the arrow inside the select element:*/
//   .select-selected:after {
//     position: absolute;
//     content: "";
//     top: 14px;
//     right: 10px;
//     width: 0;
//     height: 0;
//     border: 6px solid transparent;
//     border-color: #fff transparent transparent transparent;
//   }

//   /*point the arrow upwards when the select box is open (active):*/
//   .select-selected.select-arrow-active:after {
//     border-color: transparent transparent #fff transparent;
//     top: 7px;
//   }

//   /*style the items (options), including the selected item:*/
//   .select-items div,.select-selected {
//     color: #ffffff;
//     padding: 8px 16px;
//     border: 1px solid transparent;
//     border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
//     cursor: pointer;
//     user-select: none;
//   }

//   /*style items (options):*/
//   .select-items {
//     position: absolute;
//     background-color: DodgerBlue;
//     top: 100%;
//     left: 0;
//     right: 0;
//     z-index: 99;
//   }

//   /*hide the items when the select box is closed:*/
//   .select-hide {
//     display: none;
//   }

//   .select-items div:hover, .same-as-selected {
//     background-color: rgba(0, 0, 0, 0.1);
//   }

.chart-container {
  background-color: $secondary-theme-color;
  padding: 1.5rem;
  margin-bottom: 24px;
  // border: 1px solid $light-grey;
  border-radius: 0.25rem;
}

.totals-box {
  background-color: $secondary-theme-color;
  // border: 1px solid $light-grey;
  border-radius: 0.25rem;

  display: flex;
  justify-content: space-between;

  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem;
  align-items: center;

  .totals-content {
    flex: 2;
    color: white;

    .totals-name {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .totals-value {
      font-size: 30px;
      font-weight: 600;
      font-family: "Source Sans Pro", sans-serif;
      color: $main-theme-color;
    }
  }

  .totals-icon {
    flex: 1;
    font-size: 60px;
    text-align: right;
    opacity: 0.38;
  }
}

.close-overlay {
  display: block;
  position: fixed;
  background: rgba(0, 0, 0, 0.233);
  left: 0;
  top: 1px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

@media screen and (max-width: $breakpoint-md) {
  .totals-name {
    font-size: 14px;
  }

  .totals-value {
    font-size: 22px;
  }
}

.chart-border {
  border-radius: 0.25rem !important;
  // border: 1px solid $light-grey !important;
}

.btn-text {
  font-size: 15px;
}

.navbar-button {
  border-radius: 50px;
  padding: 4px 16px;
  transition: all .2s ease;
  border: none;
  cursor: pointer;

  &.button-default {
    color: $secondary-theme-color;
    background-color: $main-theme-color;
    border: 1px solid $secondary-theme-color;

    &:hover {
      background-color: $secondary-theme-color;
      color: $main-theme-color;
      border: 1px solid $main-theme-color;
    }
  }

  &.button-secondary {
    color: $main-theme-color;
    background-color: $secondary-theme-color;
    border: 1px solid $main-theme-color;

    &:hover {
      background-color: $main-theme-color;
      color: $secondary-theme-color;
      border: 1px solid $secondary-theme-color;
    }
  }

  &.button-success {
    color: white;
    background-color: $success-theme-color;
    border: 1px solid $secondary-theme-color;

    &:hover {
      background-color: $secondary-theme-color;
      color: white;
      border: 1px solid $success-theme-color;
    }
  }

  &.button-danger {
    color: white;
    background-color: $danger-theme-color;
    border: 1px solid $secondary-theme-color;

    &:hover {
      background-color: $secondary-theme-color;
      color: white;
      border: 1px solid $danger-theme-color;
    }
  }

  &.disabled-button {
    cursor: default;
  }
}

.dx-popup-footer {
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.quantity-container {
  display: inline;
  &.product-card {
    position: absolute;
    left: 15px;
    top: 15px;
  }
  &.product-single {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 3px;
    border: 2px solid white;
  }
  &.red {
    .red {
      background: $danger-red;
      border: 2px solid rgba(255, 255, 255, 0);
    }
  }
  &.yellow {
    .yellow {
      background: $yellow;
      border: 2px solid rgba(255, 255, 255, 0);;
    }
  }
  &.green {
    .green {
      background: $green;
      border: 2px solid rgba(255, 255, 255, 0);;
    }
  }
}
