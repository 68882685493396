
$main-theme-color : #ffd400;
$main-theme-color-lighter-20 : #ffdf40;
$main-theme-color-darker-20 : #ccaa00;
$main-theme-color-70: rgba(255, 212, 0, .7);
$main-theme-color-50: rgba(255, 212, 0, .5);
$main-theme-color-20: rgba(255, 212, 0, .2);
$main-theme-color-10: rgba(255, 212, 0, .1);

$secondary-theme-color : #363640;
$secondary-theme-color-darker-20 : #2b2b33;
$secondary-theme-color-90: rgba(54, 54, 64, .9);
$secondary-theme-color-70: rgba(54, 54, 64, .7);
$secondary-theme-color-50: rgba(54, 54, 64, .5);
$secondary-theme-color-20: rgba(54, 54, 64, .2);

$success-theme-color : #39a397;

$danger-theme-color : #d82525; 

$coral: #FF8A5B;
$coral-darker-20: #ff5916;
$coral-50: rgba(255, 138, 91, .5);
$coral-20: rgba(255, 138, 91, .2);

$linen: #FCEADE;
$linen-darker-20: #f4b387;
$linen-50: rgba(252, 234, 222, .5);
$linen-20: rgba(252, 234, 222, .2);

$pink: #EA526F;
$pink-darker-20: #e11b41;
$pink-50: rgba(234, 82, 111, .5);
$pink-20: rgba(234, 82, 111, .2);

$light-grey: #ededed;

// $main-theme-color : #C97064;
// $main-theme-color-darker-20 : #b34c3e;
// $main-theme-color-50: rgba(201, 112, 100, .5);
// $main-theme-color-20: rgba(201, 112, 100, .2);



$wsh-red: #cc3333;
$wsh-red-10: rgba(204, 51, 51, 0.1);
$wsh-red-20: rgba(204, 51, 51, 0.2);
$wsh-red-50: rgba(204, 51, 51, 0.5);
$wsh-red-70: rgba(204, 51, 51, 0.7);
$wsh-red-dark-25: #b2191e;
$green: #39a397;
$danger-red: #ff0000;
$danger-red-30: rgba(255, 0, 0, 0.3);
$green-30: rgba(139, 195, 74, .3);
$green-50: rgba(139, 195, 74, .5);
$text-gray: #555;
$bg-dirty-white: #f5f5f5;
$gray-light: #b3b3b3;
$gray-lighter: #b3b3b370;
$icon-green: #8bc34a;
$icon-blue: #2aabe4;
$icon-red: #fe0000;
$icon-purple: #564AA3;
$yellow: #feed00;
$orange: #ff9100;
$white: #fff;
$black: #000;
$border-color: #e6ecf5;
$main-red-layout: #D80E15;
$main-gray-layout: #363535;
$main-gray-layout-50: #737272;
$login-color: #2aabe4;

$breakpoint-xl : 1440px;
$breakpoint-lg : 1200px;
$breakpoint-md : 992px;
$breakpoint-sm : 768px;
$breakpoint-xs : 499px;
$breakpoint-xxs : 420px;

$header-height        : 65px;
$offscreen-size       : 280px;

@mixin overxxxl {
    @media (min-width: $breakpoint-xxl) {
      @content;
    }
}
@mixin xxl {
    @media (max-width: $breakpoint-xxl) {
      @content;
    }
}
@mixin xl {
    @media (max-width: $breakpoint-xl) {
      @content;
    }
}
@mixin lg {
    @media (max-width: $breakpoint-lg) {
      @content;
    }
}
@mixin md {
    @media (max-width: $breakpoint-md) {
      @content;
    }
}
@mixin sm {
  @media (max-width: $breakpoint-sm) {
    @content;
  }
}
@mixin xs {
  @media (max-width: $breakpoint-xs) {
    @content;
  }
}
@mixin xxs {
  @media (max-width: $breakpoint-xxs) {
    @content;
  }
}
@mixin xxxs {
  @media (max-width: $breakpoint-xxxs) {
    @content;
  }
}