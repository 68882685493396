@import "./variables.scss";

.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 5px 5px 5px 50px;
    width: 300px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 4px rgba(255, 255, 255, 0.1);
    color: white;
    font-size: 12px;

    &.toast-error {
        background-size: 15px !important;
        background-color: $danger-theme-color;
    }

    &.toast-success {
        background-size: 15px !important;
        background-color: $success-theme-color;
    }
}

.gray-table .dx-row.dx-header-row {
    background: rgba(228, 228, 228, 0.5);
    color: black !important;
}

.gray-table .dx-datagrid-table .dx-row>td .dx-sort-indicator,
.gray-table .dx-datagrid-table .dx-row>td .dx-sort {
    color: $wsh-red !important;
}

.gray-table .dx-datagrid-headers .dx-datagrid-table .dx-row>td:hover .dx-datagrid-text-content {
    color: $wsh-red !important;
}

.gray-table .dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content {
    white-space: pre-wrap !important;
    text-align: center;
    text-overflow: clip;
}


/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.5);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.6);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $secondary-theme-color-50;
}

.mat-menu-item .mat-icon {
    margin-right: 16px;
    vertical-align: middle;
    font-size: 16px;
    margin: auto;
    line-height: 22px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $secondary-theme-color !important;
}

.dx-form-group-caption {
    color: $main-theme-color;
}

.dx-form-group-with-caption>.dx-form-group-content {
    border-top: 1px solid $main-theme-color;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 4px;
}

.dx-field-item-required-mark {
    color: $main-theme-color;
}

.dx-item.dx-box-item {
    margin-bottom: -0.2rem !important;
}

.sidebar-container {
    .mat-expansion-panel-header {
        padding: 0px 3px !important;
    }
    .mat-expansion-panel-body {
        padding: 0 0px 16px !important;
    }
}

.product-quantity-input {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
    padding-bottom: 4px;
    margin: 0px 8px;
}
