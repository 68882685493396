@import './variables.scss';

// Text 
//color, size, height, weight 
.text-gray { color: $text-gray !important }
.text-success { color: $success-theme-color !important }
.text-gray-light { color: $gray-light !important }
.text-gray-light-hoverable:hover { color: $text-gray !important }
.text-main { color: $main-theme-color !important; }
.text-secondary { color: $secondary-theme-color !important; }
.text-green { color: $green !important }
.text-icon-red { color: $icon-red !important }
.text-icon-green { color: $icon-green !important }
.text-icon-blue { color: $icon-blue !important; }
.viber-color { color: #665CAC !important }
.whatsApp-color { color: #25D366 !important }
.fb-color { color: #4267B2 !important }
.green-text { color: $icon-green !important }
.red-text { color: $icon-red !important }
.white-text { color: white !important }
//
.text-bold { font-weight: 500; }
.text-bolder { font-weight: 600; }
//
.text-sm { font-size: .7rem; }
.text-md { font-size: .9rem; }
.text-l { font-size: 1.2rem; }
.text-xl { font-size: 1.5rem; }

.lh-33 { line-height: 33px !important; }
.fs-20 { font-size: 20px !important; }
.fs-14 { font-size: 14px !important; }
.fs-12 { font-size: 12px !important; }
.fs-11 { font-size: 11px !important; }
//
.text-decoration-none { text-decoration: none; }

// Background color

.bg-icon-red { background: $icon-red !important; }
.bg-icon-green { background: $icon-green !important; }
.bg-icon-blue { background: $icon-blue !important; }
.bg-main { background: $main-theme-color !important; }
.bg-secondary { background: $secondary-theme-color !important; }
.bg-green { background: $green !important; }
.bg-yellow { background: $yellow !important; }
.bg-gray { background: $gray-lighter !important; }

// width, height

.width-90 { width: 90%; }
.width-80 { width: 80%; }
.width-70 { width: 70%; }
.width-60 { width: 60%; }
.width-50 { width: 50%; }
.width-40 { width: 40%; }
.width-30 { width: 30%; }
.width-20 { width: 20%; }

.height-38p { height: 38px; }
.height-30p { height: 30px; }
.height-20p { height: 20px; }

.width-100vw { width: 100vw; }
.max-width { width: 100%; }
.width-200 { width: 200px; }
.height-2 { height: 2rem; }
.height-3 { height: 1.75rem; }
.min-height-50vh { height: 50vh; }
.max-height-50 { max-height: 50px; }

// margin, padding, border, outline

.mt-minus15 { margin-top: -15px; }
.mt--3 { margin-top: -3px; }
.border-radius-0 { border-radius: 0 !important; }
.border-radius-4 { border-radius: 4 !important; }
.outline-none { outline: none !important; }


//other

.shadow-none { box-shadow: none; }
.overflow-hidden { overflow: hidden; }
.opacity0 { opacity: 0; }
.opacity8 { opacity: .8; }
.visible { opacity: 1; }
.pull-right { float: right; }
.pull-left { float: left; }
.clickable { cursor: pointer; }
.z-index-100 { z-index: 100 !important; }
.drop-shadow {
    -webkit-box-shadow: 0px 0px 12px 0px rgba(179,179,179,0.64);
    -moz-box-shadow: 0px 0px 12px 0px rgba(179,179,179,0.64);
    box-shadow: 0px 0px 12px 0px rgba(179,179,179,0.64);
}
.hidden { visibility: hidden; }
.shortAnim {
    transition: all .2s ease;
    -moz-transition:    all .2s ease;
    -ms-transition:     all .2s ease;
    -o-transition:      all .2s ease;
    -webkit-transition: all .2s ease;
}
.normalAnim {
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    -webkit-transition: all .5s ease;
}
.anim {
    transition: all 2s ease;
    -moz-transition: all 2s ease;
    -ms-transition: all 2s ease;
    -o-transition: all 2s ease;
    -webkit-transition: all 2s ease;
}
.noselect, img {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.scale-07 {
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
}

.animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
}

.two-text-line {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -webkit-opacity: 1;
    }
    0% {
        -webkit-transform: translateY(1rem);
        -webkit-opacity: 0;
    }
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
}


@mixin overxxxl {
    @media (min-width: $breakpoint-xxl) {
      @content;
    }
}
@mixin xxl {
    @media (max-width: $breakpoint-xxl) {
      @content;
    }
}
@mixin xl {
    @media (max-width: $breakpoint-xl) {
      @content;
    }
}
@mixin lg {
    @media (max-width: $breakpoint-lg) {
      @content;
    }
}
@mixin md {
    @media (max-width: $breakpoint-md) {
      @content;
    }
}
@mixin sm {
  @media (max-width: $breakpoint-sm) {
    @content;
  }
}
@mixin xs {
  @media (max-width: $breakpoint-xs) {
    @content;
  }
}
@mixin xxs {
  @media (max-width: $breakpoint-xxs) {
    @content;
  }
}
@mixin xxxs {
  @media (max-width: $breakpoint-xxxs) {
    @content;
  }
}